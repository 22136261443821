import React, { useMemo, useState } from 'react';

import {
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from 'react-router-dom';
import { formatDateTime, getEventImage } from './utils.js';
import { useEvents, useFilterData } from './hooks/index.js';
import SelectTableCell from './SelectTableCell.jsx';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
}

const Events = () => {
  const [ page, setPage ] = useState(0)
  const [ order, setOrder ] = useState(ORDER.DESC);
  const [ orderBy, setOrderBy ] = useState(null);
  const [ selectedFilters, setSelectedFilters ] = useState({ cities: [], categories: [] });
  const history = useHistory()
  // filters = { categories: [{id: <Integer>, name: <String>, deleted: <Integer>}], cities: [<String>] }
  const { isLoading: loadingFilters, filters } = useFilterData();
  const { events, totalEvents, isLoading, refresh } = useEvents({ page, orderBy, order, ...selectedFilters });

  const onFilterChange = (fitler, options) => {
    setSelectedFilters({
      ...selectedFilters,
      [fitler]: options
    })
  }

  // * TablePagination component is 0-based, while the API is 1-based
  const onPageChange = (_event, newPage) => setPage(newPage)

  // Fucntion handling sorting for events
  const sortHandler = field => () => {
    const isAsc = order === ORDER.ASC;
    setOrder(isAsc ? ORDER.DESC : ORDER.ASC);
    setOrderBy(field);
  }

  const citiesFilterOptions = useMemo(
    () => filters?.cities?.map(city => ({ value: city, label: city })) || [],
    [ filters?.cities ]
  );

  return (
    <Container>
      <Typography variant='h1'>All Events</Typography>
      <TableContainer
        component={Paper}
        sx={{
          mt: 2,
          maxHeight: '70vh',
          '& .MuiTableCell-head, & .MuiTablePagination-root': {
            backgroundColor: 'primary.dark',
            color: 'white',
          }
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center" onClick={refresh}>
                <IconButton aria-label='refresh' color='warning'>
                  <RefreshIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "date"}
                  direction={order}
                  onClick={sortHandler("date")}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <SelectTableCell
                label={'City'}
                options={citiesFilterOptions}
                onChange={(options) => onFilterChange('cities', options)}
                selected={selectedFilters.cities}
                loading={loadingFilters}
              />
              <TableCell align="center">Source</TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "responded"}
                  direction={order}
                  onClick={sortHandler("responded")}
                >
                  Responded
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "date_added"}
                  direction={order}
                  onClick={sortHandler("date_added")}
                >
                  Date Added
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && events.map((event) => (
              <TableRow
                key={`event-${event.id}`}
                className="clickable-row"
                onClick={(e) => {
                  // * Prevent navigation when clicking on a link inside the row
                  if (e.nativeEvent.target !== e.nativeEvent.target.parentElement.querySelector('td > a')) {
                    history.push(`/event/${event.id}`)
                  }
                }}
              >
                <TableCell className='thumbnail-cell' padding="none">
                  <img
                    height={70}
                    src={getEventImage(event?.image_url)}
                    alt=""
                    referrerPolicy="no-referrer"
                  />
                </TableCell>
                <TableCell align="center">{event.id}</TableCell>
                <TableCell align="left">{event.title}</TableCell>
                <TableCell align="center">{formatDateTime(event.date)}</TableCell>
                <TableCell align="center">{event.city}</TableCell>
                {/* <TableCell align="center">
                  {event.categories.map((category) => (<Chip key={`${event.id}-${category}`} label={category} />))}
                </TableCell> */}
                <TableCell align="center">
                  <a target='_blank' rel="noreferrer" href={event.source_url}>Source</a>
                </TableCell>
                <TableCell align="center">{event.responded}</TableCell>
                <TableCell align="center">{formatDateTime(event.date_added)}</TableCell>
              </TableRow>
            ))}

            {isLoading && (
              <TableRow>
                <TableCell sx={{ p: 2 }} colSpan={8} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          sx={{ position: 'sticky', bottom: 0 }}
          count={totalEvents}
          rowsPerPage={events?.length}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={onPageChange}
        />
      </TableContainer>
    </Container>
  )
}
export default Events;