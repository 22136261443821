import { useCallback, useEffect, useState } from  'react';
import { useAwsLambdaContext } from '../context/AwsLambda';
import { useSnackbar } from '../context/Snackbar';
import { createEventsArrayHash, sortByPriority } from '../sectionsUtils';
import { getSavedFilterData, setSavedFilterData } from '../storageUtils';

export const useFilterData = () => {
  const [ filters, setFilters ] = useState(getSavedFilterData())
  const { getFilterData, isLoading } = useAwsLambdaContext();

  useEffect(() => {
    async function fetchData() {
      const data = await getFilterData()
      setFilters(data)
      setSavedFilterData(data)
    }
    if (!filters) {
      fetchData()
    }
  }, [ filters, getFilterData ])

  return { isLoading, filters }
}

export const useEvent = (id) => {
  const [ event, setEvent ] = useState({})
  const { getEvent, isLoading } = useAwsLambdaContext();

  const _getEvent = useCallback(async () => {
    if (!id) return
    const response = await getEvent(id)
    if (response?.event) {
      setEvent(response.event)
    }
  }, [ id, getEvent ])

  const refresh = () => {
    setEvent({})
    _getEvent()
  }

  useEffect(() => {
    _getEvent()
  }, [ _getEvent ])

  return { isLoading, event, refresh }
}

export const useEvents = ({ order, orderBy, page, cities, categories }) => {
  const [ events, setEvents ] = useState([])
  const [ totalEvents, setTotalEvents ] = useState(0)
  const { getEvents, isLoading } = useAwsLambdaContext();

  const _getEvents = useCallback(async () => {
    // * TablePagination component is 0-based, while the API is 1-based
    const options = {
      page: page + 1,
      cities: cities?.length ? cities.map(city => city.value) : undefined,
      categories: categories?.length ? categories.map(category => category.value) : undefined,
      sortOptions: order && orderBy
        ? {
          field: orderBy,
          order: order.toUpperCase()
        }
        : undefined
    }

    const response = await getEvents(options)
    if (response) {
      const { events, totalEvents } = response
      setEvents(events)
      setTotalEvents(totalEvents)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ getEvents, order, orderBy, page, cities, categories ])

  const refresh = () => {
    setEvents([])
    setTotalEvents(0)
    _getEvents()
  }

  useEffect(() => {
    _getEvents()
  }, [ _getEvents ])

  return { isLoading, events, totalEvents, refresh }
}

export const useSubscriptions = () => {
  const [ subscriptions, setSubscriptions ] = useState([])
  const { getSubscriptions, isLoading } = useAwsLambdaContext();

  const _getSubscriptions = useCallback(async () => {
    // * TablePagination component is 0-based, while the API is 1-based
    const response = await getSubscriptions()
    setSubscriptions(response?.data?.members || [])
  }, [ getSubscriptions ])

  const refresh = () => {
    setSubscriptions([])
    _getSubscriptions()
  }

  useEffect(() => {
    _getSubscriptions()
  }, [ _getSubscriptions ])

  return { isLoading, subscriptions, refresh }
}

export const useSections = () => {
  const [ sectionsMap, setSectionsMap ] = useState({})
  const [ sections, setSections ] = useState([])
  const { getSections, isLoading } = useAwsLambdaContext();
  const { showSnackbar } = useSnackbar()

  const _getSections = useCallback(async () => {
    const response = await getSections()
    if (!response || response?.error) {
      return false
    }
    const _sections = response?.sections || []
    var _sectionsMap = {}

    for (let i = 0; i < _sections.length; i++) {
      const section = _sections[i]
      section.isDirty = false
      section.events.sort(sortByPriority)
      _sectionsMap[`${section.id}`] = {
        ...section,
        eventsHash: createEventsArrayHash(section.events)
      }
    }

    setSectionsMap(_sectionsMap)
    setSections(_sections)
    return true
  }, [ getSections ])

  const refresh = async () => {
    const fetched = await _getSections()
    if (!fetched) {
      showSnackbar({ severity: 'error', message: 'There was an error while refreshing' })
    }
  }

  useEffect(() => {
    _getSections()
  }, [ _getSections ])

  return { isLoading, sections, sectionsMap, refresh }
}