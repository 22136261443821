import { FilterList } from '@mui/icons-material';
import {
  Badge,
  Chip,
  CircularProgress,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const SelectTableCell = ({ label, options, onChange, selected, loading }) => {
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ menuSelectedItems, setMenuSelectedItems ] = useState(selected);

  useEffect(() => {
    setMenuSelectedItems(selected);
  }, [ selected ]);

  const handleLabelClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => {
    onChange(menuSelectedItems);
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    if (menuSelectedItems.find(s => s.value === option.value)) {
      // remove option from filters
      setMenuSelectedItems([ ...menuSelectedItems.filter(s => s.value !== option.value) ]);
    } else {
      // add option to filters
      setMenuSelectedItems([ ...menuSelectedItems, option ]);
    }
  };

  const onReset = () => {
    setMenuSelectedItems([]);
    onChange([]);
    setAnchorEl(null);
  };

  return (
    <TableCell>
      <Tooltip title={`Filter by ${label}`}>
        <TableSortLabel
          active
          id={`${label}-filter-menu-button`}
          direction='desc'
          IconComponent={FilterList}
          onClick={handleLabelClick}
          aria-controls={anchorEl ? `${label}-filter-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
        >
          <Badge
            badgeContent={menuSelectedItems?.length || 0}
            color='secondary'
            showZero={false}
            componentsProps={{ badge: { sx: { color: '#fff' } } }}
          >
            {label}
          </Badge>
        </TableSortLabel>
      </Tooltip>

      <Menu
        id={`${label}-filter-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          dense: true,
          'aria-labelledby': `${label}-filter-menu-button`,
          sx: { py: 0 },
        }}
      >
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={24} />
          </MenuItem>
        ) : (
          options.map(option => {
            const isSelected = Boolean(menuSelectedItems.find(s => s.value === option.value));
            return (
              <MenuItem selected={isSelected} key={option.value} onClick={() => handleOptionClick(option)}>
                <ListItemText primaryTypographyProps={{ textAlign: 'center' }} primary={<Chip
                  label={option.label}
                  size='small'
                  variant={isSelected ? 'filled' : 'outlined'}
                />}
                />
              </MenuItem>
            );
          })
        )}

        {Boolean(menuSelectedItems.length) && <Divider />}
        {Boolean(menuSelectedItems.length) &&
          <MenuItem
            sx={{ justifyContent: 'center', color: 'error.dark' }}
            onClick={onReset}
          >Reset</MenuItem>
        }
      </Menu>
    </TableCell>
  );
};

export default SelectTableCell;