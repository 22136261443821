import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  TextField,
  IconButton,
  List,
  ListItem as MuiListItem,
  ListItemText,
  Typography,
  Tooltip,
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Delete as DeleteIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

import EventImage from './EventImage.jsx';
import EventFieldEditButton from './EventFieldEditButton.jsx';
import CategoriesSelect from './CategoriesSelect.jsx';
import { dateStringToUTCString, formatDateTime, formatISODateTime } from './utils.js';
import { useEvent } from './hooks/index';
import { useAwsLambdaContext } from './context/AwsLambda.jsx';

const getEventCategoriesDefaultValue = event => event?.categories || []

const ListItem = ({ sx = {}, label, value, secondaryText, secondaryAction, divider = true }) => (
  <MuiListItem sx={{ ...sx }} divider={divider} secondaryAction={secondaryAction}>
    <ListItemText
      sx={{ maxWidth: 400,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 200,
      }}
      primary={<>
        {label}:{' '}
        <Typography
          variant='subtitle1'
          component='span'
          color='text.secondary'
          sx={{ fontWeight: 800 }}
        >{value}</Typography>
      </>}
      secondary={secondaryText}
    />
  </MuiListItem>
)

const FieldSaveButton = ({ onClick }) => (
  <Tooltip title='Save'>
    <IconButton color='success' onClick={onClick}>
      <SaveIcon />
    </IconButton>
  </Tooltip>
)

const listSx = {
  bgcolor: 'background.paper',
  py: 0,
  width: '100%',
  maxWidth: 500,
  borderRadius: 1,
  '& .MuiListItem-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  '& .MuiCollapse-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    lineHeight: '1rem',
    py: 2,
    position: 'relative',
  },
  '& .MuiCollapse-root .MuiTextField-root': {
    ml: 2,
    width: 'calc(100% - 90px)',
  },
  '& .MuiCollapse-root .MuiIconButton-root': {
    position: 'absolute',
    right: 16,
    top: '25%',
  },
}

const deletedSx = {
  textDecoration: 'line-through',
  opacity: 0.5
}

const Event = () => {
  const descriptionInputRef = useRef()
  const respondedInputRef = useRef()
  const locationInputRef = useRef()
  const dateInputRef = useRef()
  const organiserInputRef = useRef()
  const ticketLinkInputRef = useRef()
  const titleInputRef = useRef()
  const sourceUrlInputRef = useRef()

  const [ editModes, setEditModes ] = useState({
    date: false,
    categories: false,
    location: false,
    responded: false,
    description: false,
    organiser_name: false,
    ticket_link: false,
    title: false,
    source_url: false,
  })
  const [ descriptionIsExpanded, setDescriptionIsExpanded ] = useState(false)
  const { id: eventId } = useParams()
  const { event, refresh, isLoading } = useEvent(eventId)
  const { updateEvent, handleNewEventImage, deleteEvent } = useAwsLambdaContext()
  const [ categoriesValue, setCategoriesValue ] = useState(getEventCategoriesDefaultValue(event))

  const onUpdate = (updated, callback = () => {}) => {
    if (updated) {
      refresh()
      callback()
    }
  }

  const onDelete = async () => {
    onUpdate(await deleteEvent(eventId))
  }

  const onCategoriesSave = async () => {
    const categories = categoriesValue?.map(c => c.id) || []
    //  check if changed, then save with API
    const sortedOld = event.categories?.map(c => c.id)?.sort((a, b) => a - b)
    const sortedNew = categories.sort((a, b) => a - b)
    if (JSON.stringify(sortedOld) !== JSON.stringify(sortedNew)) {
      onUpdate(
        await updateEvent({ id: eventId, updateData: { categories } }),
        () => setEditModes(prev => ({ ...prev, categories: false }))
      )
    }
  }

  const onFieldSave = async (inputRef, fieldName) => {
    const value = inputRef.current?.value
    let updateData = { [fieldName]: value }

    if (fieldName === 'date') {
      const UTCdateString = dateStringToUTCString(value)
      if (value !== formatISODateTime(event.date)) {
        updateData = { date: UTCdateString }
      } else {
        return
      }
    } else if (value === event[fieldName]) {
      return
    }

    onUpdate(
      await updateEvent({ id: eventId, updateData }),
      () => setEditModes(prev => ({ ...prev, [fieldName]: false }))
    )
  }

  const onImageUpload = async (e) => {
    const file = e.target.files[0]
    if (file) {
      onUpdate(handleNewEventImage(event.id, file))
    }
  }

  return (
    <Container sx={event?.deleted ? deletedSx : {} }>
      {!!event?.deleted &&
        <Chip label='Deleted' color='error' variant='filled' sx={{ position: 'fixed', zIndex: 2 }} />
      }

      {isLoading
        ? <CircularProgress sx={{ m: '25vh auto', display: 'block' }} />
        : (<>
          <Typography variant='h1' sx={{ textAlign: 'center' }}>Event #{eventId}</Typography>
          <Box sx={{ my: 2, display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
            <Button variant='outlined' startIcon={<RefreshIcon />} color='warning' onClick={refresh}>
              Refresh
            </Button>
            <Button
              disabled={!!event.deleted}
              variant='outlined'
              startIcon={<DeleteIcon />}
              color='error'
              onClick={onDelete}
            >
              Delete
            </Button>
          </Box>

          <Box sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
          }}>
            <EventImage event={event} onUpload={onImageUpload} />

            <List sx={listSx}>
              <ListItem
                label='ID'
                value={event.id}
                secondaryText={<a target='_blank' rel="noreferrer" href={`https://eventor.com.cy/event/${event.id}`}>View on eventor.com.cy</a>}
              />

              <ListItem
                label='Title'
                value={editModes.title
                  ? <TextField
                    fullWidth
                    inputRef={titleInputRef}
                    placeholder='Event title'
                    defaultValue={event.title}
                    type='text'
                    color='secondary'
                  />
                  : event.title
                }
                secondaryAction={
                  <>
                    {editModes.title && <FieldSaveButton onClick={() => onFieldSave(titleInputRef, 'title')} />}
                    <EventFieldEditButton
                      fieldName='title'
                      editMode={editModes.title}
                      onToggleEditMode={() => setEditModes(prev => ({ ...prev, title: !prev.title }))}
                    />
                  </>
                }
              />

              <ListItem
                divider={!descriptionIsExpanded}
                label='Description'
                value={event.description?.slice(0, 50)}
                sx={{ pr: 12, '& .MuiListItemText-root .MuiTypography-subtitle1': { fontStyle: 'italic' } }}
                secondaryAction={<>
                  {event.description?.length > 50 && (
                    <Tooltip title={`${descriptionIsExpanded ? 'Hide' : 'Show'} full description`}>
                      <IconButton onClick={() => setDescriptionIsExpanded(!descriptionIsExpanded)}>
                        {descriptionIsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Tooltip>
                  )}
                  <EventFieldEditButton
                    fieldName='description'
                    editMode={editModes.description}
                    onToggleEditMode={() => setEditModes(prev => ({ ...prev, description: !prev.description }))}
                  />
                </>}
              />
              <Collapse
                unmountOnExit
                sx={{ pl: 2 }}
                in={descriptionIsExpanded || editModes.description}
                timeout="auto"
              >
                <pre style={{ textWrap: 'wrap', margin: 0 }}>
                  {editModes.description ? (
                    <>
                      <TextField
                        fullWidth
                        multiline
                        inputRef={descriptionInputRef}
                        placeholder='Event description'
                        defaultValue={event.description}
                        type='text'
                        color='secondary'
                      />
                      <FieldSaveButton onClick={() => onFieldSave(descriptionInputRef, 'description')} />
                    </>
                  ) : (
                    <Typography variant='subtitle1' component='span' color='text.secondary'>
                      {event.description}
                    </Typography>
                  )}
                </pre>
              </Collapse>


              <ListItem
                label='Local date'
                value={formatDateTime(event.date)}
                secondaryText={`UTC date: ${event.date}`}
                secondaryAction={
                  <EventFieldEditButton
                    fieldName='date'
                    editMode={editModes.date}
                    onToggleEditMode={() => setEditModes(prev => ({ ...prev, date: !prev.date }))}
                  />
                }
              />
              <Collapse
                unmountOnExit
                in={editModes.date}
                timeout="auto"
              >
                <TextField
                  fullWidth
                  inputRef={dateInputRef}
                  placeholder='Event local date'
                  defaultValue={formatISODateTime(event.date)}
                  type='datetime-local'
                  color='secondary'
                />
                <FieldSaveButton onClick={() => onFieldSave(dateInputRef, 'date')} />
              </Collapse>

              <ListItem
                label='City'
                value={event.city}
              />

              <ListItem
                label='Organiser'
                value={editModes.organiser_name
                  ? <TextField
                    fullWidth
                    inputRef={organiserInputRef}
                    placeholder='Organiser name'
                    defaultValue={event.organiser_name}
                    type='text'
                    color='secondary'
                  />
                  : event.organiser_name
                }
                secondaryAction={
                  <>
                    {editModes.organiser_name && <FieldSaveButton onClick={() => onFieldSave(organiserInputRef, 'organiser_name')} />}
                    <EventFieldEditButton
                      fieldName='organiser'
                      editMode={editModes.organiser_name}
                      onToggleEditMode={() => setEditModes(prev => ({ ...prev, organiser_name: !prev.organiser_name }))}
                    />
                  </>
                }
              />

              <ListItem
                label='Categories'
                value={editModes.categories
                  ? <CategoriesSelect
                    sx={{ width: 'calc(100% - 92px)', display: 'inline-block' }}
                    value={categoriesValue}
                    setValue={setCategoriesValue}
                    defaultValue={getEventCategoriesDefaultValue(event)}
                  />
                  : event?.categories?.map(({ id, name }) => (
                    <Chip key={`category-${id}`} label={name} sx={{ mr: 0.5 }} size='small' color='secondary' />
                  ))
                }
                secondaryAction={
                  <>
                    {editModes.categories && <FieldSaveButton onClick={onCategoriesSave} />}
                    <EventFieldEditButton
                      fieldName='categories'
                      editMode={editModes.categories}
                      onToggleEditMode={() => setEditModes(prev => ({ ...prev, categories: !prev.categories }))}
                    />
                  </>
                }
              />

              <ListItem
                divider={!editModes.location}
                label='Location'
                value={event?.location}
                secondaryText={event?.location
                  ? <a target='_blank' rel="noreferrer" href={`https://maps.google.com/?q=${event?.location}`}>View on Google Maps</a>
                  : `When there is no location, city ('${event?.city}') will be used instead.`
                }
                secondaryAction={
                  <EventFieldEditButton
                    fieldName='location'
                    editMode={editModes.location}
                    onToggleEditMode={() => setEditModes(prev => ({ ...prev, location: !prev.location }))}
                  />
                }
              />
              <Collapse
                unmountOnExit
                in={editModes.location}
                timeout="auto"
              >
                <TextField
                  fullWidth
                  inputRef={locationInputRef}
                  placeholder='Event location or address'
                  defaultValue={event?.location}
                  type='text'
                  color='secondary'
                />
                <FieldSaveButton onClick={() => onFieldSave(locationInputRef, 'location')} />
              </Collapse>

              <ListItem
                divider={!editModes.responded}
                label='Responded'
                value={event.responded}
                secondaryText='Number of responses. Sets the priority of the event.'
                secondaryAction={
                  <EventFieldEditButton
                    fieldName='responses (priority)'
                    editMode={editModes.responded}
                    onToggleEditMode={() => setEditModes(prev => ({ ...prev, responded: !prev.responded }))}
                  />
                }
              />
              <Collapse
                unmountOnExit
                in={editModes.responded}
                timeout="auto"
              >
                <TextField
                  fullWidth
                  inputRef={respondedInputRef}
                  placeholder='Number of responses (priority)'
                  defaultValue={event.responded}
                  type='text'
                  color='secondary'
                />
                <FieldSaveButton onClick={() => onFieldSave(respondedInputRef, 'responded')} />
              </Collapse>

              <ListItem
                label='Ticket link'
                value={editModes.ticket_link
                  ? <TextField
                    fullWidth
                    inputRef={ticketLinkInputRef}
                    placeholder='Ticket link'
                    defaultValue={event.ticket_link}
                    type='text'
                    color='secondary'
                  />
                  : event.ticket_link
                }
                secondaryText={
                  event.ticket_link && !editModes.ticket_link
                    ? <a target='_blank' rel="noreferrer" href={event.ticket_link}>View ticket</a>
                    : null
                }
                secondaryAction={
                  <>
                    {editModes.ticket_link && <FieldSaveButton onClick={() => onFieldSave(ticketLinkInputRef, 'ticket_link')} />}
                    <EventFieldEditButton
                      fieldName='ticket link'
                      editMode={editModes.ticket_link}
                      onToggleEditMode={() => setEditModes(prev => ({ ...prev, ticket_link: !prev.ticket_link }))}
                    />
                  </>
                }
              />

              <ListItem
                divider={false}
                label='Source URL'
                value={editModes.source_url
                  ? <TextField
                    fullWidth
                    inputRef={sourceUrlInputRef}
                    placeholder='Source URL'
                    defaultValue={event.source_url}
                    type='text'
                    color='secondary'
                  />
                  : event.source_url
                }
                sx={{ '& .MuiListItemText-root': { textWrap: 'auto' } }}
                secondaryText={
                  event.source_url && !editModes.source_url
                    ? <a target='_blank' rel="noreferrer" href={event.source_url}>View source</a>
                    : null
                }
                secondaryAction={
                  <>
                    {editModes.source_url && <FieldSaveButton onClick={() => onFieldSave(sourceUrlInputRef, 'source_url')} />}
                    <EventFieldEditButton
                      fieldName='source URL'
                      editMode={editModes.source_url}
                      onToggleEditMode={() => setEditModes(prev => ({ ...prev, source_url: !prev.source_url }))}
                    />
                  </>
                }
              />
            </List>
          </Box>
        </>)
      }
    </Container>
  )
}
export default Event